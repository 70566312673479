// -------------------------------------
// #MENU MOBILE
// -------------------------------------

.menu-mobile {
    display: none;
    position: absolute;
    top: 100%;
    left: -27px;
    width: calc(100% + 54px);
    background-color: $darkblue;
    border-top: 1px solid lighten($darkblue,10%);
    border-bottom: 1px solid lighten($darkblue,10%);
    @include breakpoint(large) {
        display: none !important;
    }
    ul {
        margin: 0;
        list-style: none;
        font-size: 0;
        background-color: $darkblue;
    }
    li {
        position: relative;
        display: inline-block;
        vertical-align: top;
        width: 50%;
        @include font-type(font400);
        font-size: 18px;
        color: #fff;
        border-bottom: 1px solid lighten($darkblue,10%);
        border-right: 1px solid lighten($darkblue,10%);
        transition: all .3s ease-in-out;
        @include breakpoint(small) {
            font-size: 20px;
        }
        a {
            text-align: center;
            display: block;
            color: #fff;
            padding: 40px 20px;
            transition: $t-bg-color;
            &:hover {
                background-color: lighten($darkblue,10%);
            }
        }
    }
    .sub-menu {
        position: absolute;
        top: 0;
        left: 100%;
        width: 100%;
        display: none;
        border-left: 1px solid lighten($darkblue,15%);
        background-color: lighten($darkblue,10%);
        z-index: 99;
        overflow-y: auto;
        max-height: 327px;
        @include breakpoint(small) {
            max-height: 339px;
        }
        li {
            border-color: lighten($darkblue,15%);
            width: 100%;
        }
        a {
            &:hover {
                background-color: lighten($darkblue,15%);
            }
        }
    }
}

// -------------------------------------
// #HEADER
// -------------------------------------

header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $darkblue;
    z-index: 1100;
    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 100%;
        height: 160px;
        transform: translate(-50%, 50%);
        background-color: $darkblue;
        border-radius: 50%;
        z-index: -1;
        display: none;
        @include breakpoint(xlarge) {
            display: block;
        }
    }
}

.menu-block {
    font-size: 0;
    position: relative;
}

.menu-logo {
    position: absolute;
    left: 0;
    top: -10px;
    z-index: 99;
    display: inline-block;
    vertical-align: middle;
    @include breakpoint(xlarge) {
        top: -50px;
    }
    h1, a {
        font-size: 0;
    }
    a {
        position: relative;
        display: block;
        width: 110px;
        height: 110px;
        z-index: 20;
        transition: $t-opacity;
        @include breakpoint(xlarge) {
            width: 240px;
            height: 240px;
        }
        &:hover {
            &:before {
                background-color: rgba(#fff,0.3);
            }
        }
        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            width: 100%;
            height: 100%;
            border-radius: 50%;
            border: 1px solid #8290DB;
            transition: $t-bg-color;
        }
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            width: calc(100% - 10px);
            height: calc(100% - 10px);
            border-radius: 50%;
            background-color: #fff;
            z-index: -1;
            box-shadow: 0 0 20px rgba(#000,0.1);
            @include breakpoint(xlarge) {
                width: calc(100% - 26px);
                height: calc(100% - 26px);
            }
        }
    }
    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 70px;
        height: auto;
        @include breakpoint(xlarge) {
            width: 150px;
        }
    }
}

.menu-nav {
    text-align: right;
    padding: 27.5px 0;
    @include breakpoint(large) {
        padding: 33px 0;
    }
    @include breakpoint(xlarge) {
        padding: 50px 0 20px 0;
    }
    @include breakpoint(wrapper) {
        padding: 50px 0 0 0;
    }
    .custom-menu-class {
        display: none;
        @include breakpoint(large) {
            display: inline-block;
            vertical-align: middle;
        }
        > ul {
            margin: 0;
            list-style: none;
            > li {
                position: relative;
                display: inline-block;
                vertical-align: middle;
                margin-right: 40px;
                @include font-type(font400);
                font-size: 20px;
                color: #fff;
                line-height: 1;
                > a {
                    position: relative;
                    color: #fff;
                    transition: $t-opacity;
                    z-index: 40;
                    &:hover {
                        opacity: 0.5;
                    }
                    &:active {
                        top: 2px;
                    }
                }
            }
        }
    }
    .sub-menu {
        visibility: hidden;
        pointer-events: none;
        text-align: left;
        position: absolute;
        top: 100%;
        left: 0;
        transform: translateY(-20px);
        width: 250px;
        margin: 0;
        padding: 45px 30px 25px 30px;
        z-index: 30;
        opacity: 0;
        transition: $t-opacity, $t-transform, visibility .3s ease-in-out;
        &:after {
            content: '';
            position: absolute;
            top: 20px;
            left: 0;
            width: 100%;
            height: calc(100% - 20px);
            background-color: #fff;
            border-radius: 5px;
            box-shadow: 0 0 10px rgba(#000,0.15);
            z-index: -1;
        }
        li {
            display: block;
            margin-bottom: 20px;
            font-size: 18px;
            line-height: 1.5;
            color: $darkblue;
            &:last-of-type {
                margin-bottom: 0;
            }
        }
        a {
            position: relative;
            color: $darkblue;
            transition: $t-color;
            &:hover {
                color: $cyan;
            }
            &:active {
                top: 2px;
            }
        }
    }
    .menu-item-has-children {
        > a {
            padding-right: 20px;
            &:after {
                content: '';
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                width: 10px;
                height: 6px;
                @include no-svg(icon-dropdown-lightblue);
            }
        }
        &:hover {
            .sub-menu {
                visibility: visible;
                pointer-events: auto;
                opacity: 1;
                transform: translateY(0);
            }
        }
    }
}

.menu-search {
    display: inline-block;
    vertical-align: middle;
    button {
        cursor: pointer;
        transition: $t-opacity;
        &:hover {
            opacity: 0.5;
        }
    }
    img {
        display: block;
        width: 35px;
        height: 35px;
        @include breakpoint(large) {
            width: 24px;
            height: 24px;
        }
    }
}

.menu-btn {
    display: none;
    @include breakpoint(wrapper) {
        display: inline-block;
        vertical-align: middle;
        margin-left: 40px;
    }
}

.menu-btn-mobile {
    display: inline-block;
    vertical-align: middle;
    margin-right: 25px;
    margin-top: -2px;
    @include breakpoint(large) {
        display: none;
    }
    .btn-label {
        @include font-type(font400);
        color: #fff;
        font-size: 16px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
        margin-top: 2px;
        @include breakpoint(340px down) {
            display: none;
        }
    }
    .hamburger-box {
        display: inline-block;
        vertical-align: middle;
    }
}

// -------------------------------------
// #SEARCH
// -------------------------------------

.search-full-block {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1200;
    background-color: rgba(#000,0.7);
    .wrapper, .page-eight-wrap {
        height: 100%;
    }
    .page-eight-wrap {
        position: relative;
    }
    .search-form {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        transform: translateY(-50%);
    }
    .search-style {
        position: relative;
        background-color: #fff;
        border-radius: 50px;
        font-size: 0;
        padding-right: 20px;
        transition: $t-box-shadow;
        @include breakpoint(xsmall) {
            padding-right: 30px;
        }
        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: calc(100% + 10px);
            height: calc(100% + 10px);
            transform: translate(-50%,-50%);
            border: 1px solid rgba(#fff,0.5);
            border-radius: 50px;
            z-index: -1;
            transition: $t-border-color, $t-bg-color;
        }
        &:focus-within {
            &:before {
                border-color: #fff;
                background-color: rgba(#fff,0.3);
            }
        }
        input {
            @include font-type(font400);
            display: inline-block;
            font-size: 20px;
            vertical-align: middle;
            height: 60px;
            margin: 0;
            padding-left: 20px;
            border-radius: 50px;
            width: calc(100% - 95px);
            margin-right: 15px;
            color: $darkblue;
            @include breakpoint(xsmall) {
                height: 80px;
                padding: 0 20px 0 30px;
            }
        }
        .search-close, .search-submit {
            display: inline-block;
            vertical-align: middle;
            cursor: pointer;
            transition: $t-opacity;
            &:hover {
                opacity: 0.5;
            }
            img {
                width: 30px;
                height: 30px;
            }
        }
        .search-submit {
            margin-right: 20px;
        }
    }
}

// -------------------------------------
//   MENU BUTTON
// -------------------------------------

.hamburger {
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
}
.hamburger:hover {
    opacity: 0.7;
}
.hamburger.is-active:hover {
    opacity: 0.7;
}
.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
    background-color: #fff;
}

.hamburger-box {
    width: 40px;
    height: 35px;
    display: inline-block;
    position: relative;
}

.hamburger-inner {
    display: block;
    top: 50%;
    transform: translateY(-50%);
}
.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 40px;
    height: 4px;
    background-color: #fff;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
}
.hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block;
}
.hamburger-inner::before {
    top: -14.5px;
}
.hamburger-inner::after {
    bottom: -14.5px;
}

.hamburger--squeeze .hamburger-inner {
    transition-duration: 0.075s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--squeeze .hamburger-inner::before {
    transition: top 0.075s 0.12s ease, opacity 0.075s ease;
}
.hamburger--squeeze .hamburger-inner::after {
    transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze.is-active .hamburger-inner {
    transform: rotate(45deg) translateY(-50%);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--squeeze.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}
.hamburger--squeeze.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}
