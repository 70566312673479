// -------------------------------------
// #COLORS
// -------------------------------------

$primary: #000000;
$caption: #9A9A9A;
$black: #3B3B3B;
$gray: #9A9A9A;
$lightgray: #F2F2F2;
$darkblue: #3C4680;
$lightblue: #EBEDF8;
$cyan: #3EB3D6;
$lightcyan: #C3F0FE;
$green: #40A513;
$red: #9d0b0b;

// -------------------------------------
// #TRANSITIONS
// -------------------------------------

$t-all: all .3s ease-in-out;
$t-color: color .3s ease-in-out;
$t-bg-color: background-color .3s ease-in-out;
$t-opacity: opacity .3s ease-in-out;
$t-transform: transform .3s ease-in-out;
$t-filter: filter .3s ease-in-out;
$t-border-color: border-color .3s ease-in-out;
$t-box-shadow: box-shadow .3s ease-in-out;

// -------------------------------------
// #OTHERS
// -------------------------------------

$line-height-sm: 1.25;
$line-height-md: 1.5;
$spacing-sm: 60px;
$spacing-md: 80px;
$spacing-lg: 100px;
$border-radius: 15px;
