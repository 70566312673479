// -------------------------------------
//   MIXINS
// -------------------------------------

// -------------------------------------
//   ARTICLE
// -------------------------------------

@mixin article($font-article, $color-article, $font-title, $color-title, $color-link, $color-hover) {
    @include clearfix;

    // IMG / IFRAME
    img, iframe {
        text-align: center;
        display: block;
        margin: 0 auto;
        max-width: 100%;
        border-radius: 15px;
        box-shadow: 0 0 20px rgba(#000,0.1);
    }
    iframe {
        border: none;
    }
    hr {
        margin: 40px auto;
    }
    .wp-block-pullquote {
        padding: 0;
    }
    .has-drop-cap:not(:focus):after {
        padding-top: 0;
    }

    // WP OLD CAPTION
    .wp-caption {
        max-width: 100% !important;
        text-align: center;
        margin: 0 auto;
        padding: 0;
        img {
            width: 100%;
            margin: 0;
        }
        .wp-caption-text {
            text-align: left;
            @include font-type($font-article);
            color: #ababab;
            font-size: 14px;
            margin: 0;
            padding: 5px 10px;
        }
        @include breakpoint(medium) {
            &.alignleft {
                float: left;
                margin-right: 15px;
                margin-bottom: 0px;
                max-width: 50% !important;
            }
            &.alignright {
                float: right;
                margin-left: 15px;
                margin-bottom: 0px;
                max-width: 50% !important;
            }
        }
    }

    // HEADING STYLES
    h1, h2, h3, h4, h5, h6 {
        @include font-type($font-title);
        &:not(.has-text-color) {
            color: $color-title;
            a {
                color: $color-title;
            }
        }
    }
    h1 {
        font-size: 28px;
        @include breakpoint(small) {
            font-size: 32px;
        }
    }
    h2 {
        font-size: 26px;
        @include breakpoint(small) {
            font-size: 30px;
        }
    }
    h3 {
        font-size: 24px;
        @include breakpoint(small) {
            font-size: 28px;
        }
    }
    h4 {
        font-size: 22px;
        @include breakpoint(small) {
            font-size: 26px;
        }
    }
    h5 {
        font-size: 20px;
        @include breakpoint(small) {
            font-size: 24px;
        }
    }
    h6 {
        font-size: 20px;
        @include breakpoint(small) {
            font-size: 22px;
        }
    }

    // LIST STYLES
    li[style*="text-align: center"] {
        list-style-position: inside;
    }
    ul:not(.wp-block-gallery), ol:not(.wp-block-gallery) {
        li {
            margin-bottom: 5px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    // TEXT STYLES
    p, li {
        @include font-type($font-article);
        font-size: 18px;
    }
    p, li {
        &:not(.has-text-color) {
            color: $color-article;
        }
    }
    p {
        line-height: 1.5;
    }
    h1, h2, h3, h4, h5, h6, p {
        img {
            margin: 0 20px 0 0;
            display: inline-block;
        }
    }

    // BUTTON / LINK STYLES
    a {
        text-decoration: underline;
        transition: $t-color, $t-bg-color;
        &:not(.has-text-color):not(.wp-block-button__link):not(.wp-block-file__button) {
            color: $color-link;
            &:hover {
                color: $color-hover;
            }
        }
        &.wp-block-file__button {
            background-color: $primary;
            color: #fff;
            &:hover {
                color: #fff;
                background-color: $color-hover;
            }
        }
        &:hover {
            color: inherit;
        }
    }
    .wp-block-button {
        color: currentColor;
        a {
            text-decoration: none;
        }
        a:not(.has-text-color) {
            color: #fff;
        }
        &.is-style-squared {
            a {
                border-radius: 50px;
            }
        }
    }

    // WP BLOCK COVER
    .wp-block-cover {
        h2, h3, h4, h5, h6, p {
            color: #fff;
            max-width: 100%;
            margin: 0;
        }
    }

    // WP MEDIA / TEXT
    .wp-block-media-text .wp-block-media-text__content {
        padding: 0 0 0 20px;
        @media screen and (max-width: 640px) {
            padding: 0;
        }
    }
    .wp-block-media-text {
        @media screen and (max-width: 640px) {
            display: block;
        }
    }

    // WP DEFAULT COLORS
    $wp_colors: (
    'black' : $black,
    'gray' : $gray,
    'lightgray' : $lightgray,
    'white' : #fff,
    'darkblue' : $darkblue,
    'cyan' : $cyan,
    'lightblue' : $lightblue,
    'green' : $green,
    );

    @each $name, $color in $wp_colors {

        .has-#{$name}-color {
            color: $color;
        }

        .has-#{$name}-background-color {
            background-color: $color;
        }
    }

    .wp-block-button__link {

        @each $name, $color in $wp_colors {

            &.has-#{$name}-color:hover,
            &.has-#{$name}-color:focus,
            &.has-#{$name}-color:active,
            &.has-#{$name}-color:visited {
                color: $color;
            }

            &.has-#{$name}-background-color:hover,
            &.has-#{$name}-background-color:focus {
                background-color: darken( $color, 10% );
            }
        }
    }

    // QUOTE STYLES
    blockquote {
        margin: 0;
        padding: 0 0 0 20px;
        @include breakpoint(small) {
            padding: 0 0 0 30px;
        }
        p {
            @include font-type($font-article);
            font-style: italic;
            font-size: 16px;
        }
    }

    // ALIGN STYLES
    .wp-block-image .alignleft {
        margin: 0 auto;
        margin-bottom: 30px;
        float: none;
        @include breakpoint(xsmall) {
            margin: 0;
            float: left;
            margin-right: 25px;
            margin-bottom: 15px;
            max-width: 50%;
        }
    }
    .wp-block-image .alignright {
        margin: 0 auto;
        margin-bottom: 30px;
        float: none;
        @include breakpoint(xsmall) {
            margin: 0;
            float: right;
            margin-left: 25px;
            margin-bottom: 15px;
            max-width: 50%;
        }
    }
    .alignfull {
        margin-left: calc(-100vw / 2 + 100% / 2);
        margin-right: calc(-100vw / 2 + 100% / 2);
        max-width: 100vw;
        img {
            width: 100vw;
        }
        iframe {
            width: 100%;
        }
    }
    .alignwide {
        @include breakpoint(medium) {
            margin: 0 -50px;
        }
        @include breakpoint(large) {
            margin: 0 -100px;
        }
        @include breakpoint(wrapper) {
            margin: 0 -120px;
        }
        img {
            width: 100%;
        }
        iframe {
            width: 100%;
        }
        &.wp-block-image {
            max-width: none;
        }
    }

    // IMAGE STYLES
    .wp-block-image {
        border-radius: 15px;
        clear: both;
        img {
            margin-bottom: 0;
        }
        figcaption {
            @include font-type($font-article);
            margin: 0;
            text-align: center;
            display: block;
            color: $caption;
            padding: 10px 0 0 0;
            background-color: rgba(#fff,0);
            width: 100%;
            font-size: 14px;
        }
    }
    div.wp-block-image {
        margin: 0;
    }

    // BLOCK GALLERY
    .wp-block-gallery {
        margin-left: 0;
        margin-bottom: 24px;
        @media screen and (max-width: 480px) {
            margin-bottom: 30px;
        }
        img {
            margin-bottom: 0;
        }
        .blocks-gallery-item {
            overflow: hidden;
            figure {
                figcaption {
                    font-size: 16px;
                    a, a:not(.has-text-color):not(.wp-block-button__link):not(.wp-block-file__button) {
                        color: #fff;
                        font-size: 16px;
                    }
                }
            }
        }
    }
    .wp-block-gallery .blocks-gallery-image, .wp-block-gallery .blocks-gallery-item {
        border-radius: 15px;
        overflow: hidden;
        @media screen and (max-width: 480px) {
            margin: 0 10px 10px 0;
        }
    }

    // WP EMBED STYLES
    .wp-block-embed {
        border-radius: 15px;
    }
    .wp-block-embed__wrapper {
        position: static;
    }
    .wp-block-embed-youtube {
        @include responsive-embed(16 by 9);
        margin-bottom: 30px;
        &.wp-embed-aspect-4-3 {
            @include responsive-embed(4 by 3);
            margin-bottom: 30px;
            @include breakpoint(small) {
                @include responsive-embed(16 by 9);
                margin-bottom: 30px;
            }
        }
        &.wp-embed-aspect-16-9 {
            @include responsive-embed(16 by 9);
            margin-bottom: 30px;
        }
    }

    // WORD WRAP
    h1, h2, h3, h4, h5, h6, p, li, a {
        overflow-wrap: break-word;
        word-wrap: break-word;
    }

    // MARGINS
    .wp-block-image figure, figure.wp-block-image, .wp-block-gallery, .wp-block-cover, .wp-block-button, img, iframe, .wp-caption, ul, ol, table, h1, h2, h3, h4, h5, h6, p, pre.wp-block-verse {
        margin-bottom: 30px;
    }
    &>:last-child {
        margin-bottom: 0 !important;
    }
}

// -------------------------------------
//   TinyMCE
// -------------------------------------

@mixin tinymce($font-article, $color-article, $color-link, $color-hover) {
    @include clearfix;
    li[style*="text-align: center"] {
        list-style-position: inside;
    }
    ul, ol {
        li {
            margin-bottom: 5px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    p, a, li {
        overflow-wrap: break-word;
        word-wrap: break-word;
        hyphens: auto;
        @include font-type($font-article);
        font-size: 18px;
    }
    p, li {
        color: $color-article;
    }
    p {
        line-height: 1.5;
    }
    a {
        text-decoration: underline;
        transition: all .3s ease-in-out;
        color: $color-link;
        &:hover {
            color: $color-hover;
        }
    }
    ul, ol, p {
        margin-bottom: 30px;
    }
    &>:last-child {
        margin-bottom: 0;
    }
}

// -------------------------------------
//   WORD WRAP
// -------------------------------------

@mixin word-wrap() {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

// -------------------------------------
//   SCRIM GRADIENT
// -------------------------------------

@mixin scrim-gradient($direction, $startColor) {

  $scrimCoordinates: (
    0: 1,
    19: 0.738,
    34: 0.541,
    47: 0.382,
    56.5: 0.278,
    65: 0.194,
    73: 0.126,
    80.2: 0.075,
    86.1: 0.042,
    91: 0.021,
    95.2: 0.008,
    98.2: 0.002,
    100: 0
  );

  $hue: hue($startColor);
  $saturation: saturation($startColor);
  $lightness: lightness($startColor);
  $stops: ();

  @each $colorStop, $alphaValue in $scrimCoordinates {
    $stop: hsla($hue, $saturation, $lightness, $alphaValue) percentage($colorStop/100);
    $stops: append($stops, $stop, comma);
  }

  background: linear-gradient($direction, $stops);

}

// -------------------------------------
//   CLEARFIX
// -------------------------------------

@mixin clearfix {
    *zoom: 1;

    &:before, &:after {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
}

// -------------------------------------
//   NO-SVG
// -------------------------------------

@mixin no-svg($name) {
    background-repeat: no-repeat;
    background-image: url(../images/#{$name}.svg), none;

    .no-svg & {
        background: url(../images/#{$name}.png);
    }
}

// -------------------------------------
//   NO BACKGROUND ATTACHMENT IN MOBILE
// -------------------------------------

@mixin no-touch-fixed {
    .no-touch & {
        background-attachment: fixed !important;
    }
}

// -------------------------------------
//   COLOR PLACEHOLDER
// -------------------------------------

@mixin placeholder-color($color) {
  &::-webkit-input-placeholder { /* WebKit browsers */
      color: $color;
  }
  &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
      color: $color;
  }
  &::-moz-placeholder { /* Mozilla Firefox 19+ */
      color: $color;
  }
  &:-ms-input-placeholder { /* Internet Explorer 10+ */
      color: $color;
  }
}

// -------------------------------------
//   ESPACOS PROPORCIONAIS
// -------------------------------------

@mixin maintain-aspect-ratio($width, $height) {
  position: relative;

  &:before{
    display: block;
    content: " ";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }

  > * {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

// -------------------------------------
//   GRADIENT
// -------------------------------------
/// Convert angle
/// @author Chris Eppstein
/// @param {Number} $value - Value to convert
/// @param {String} $unit - Unit to convert to
/// @return {Number} Converted angle
@function convert-angle($value, $unit) {
  $convertable-units: deg grad turn rad;
  $conversion-factors: 1 (10grad/9deg) (1turn/360deg) (3.1415926rad/180deg);
  @if index($convertable-units, unit($value)) and index($convertable-units, $unit) {
    @return $value
             / nth($conversion-factors, index($convertable-units, unit($value)))
             * nth($conversion-factors, index($convertable-units, $unit));
  }

  @warn "Cannot convert `#{unit($value)}` to `#{$unit}`.";
}

/// Test if `$value` is an angle
/// @param {*} $value - Value to test
/// @return {Bool}
@function is-direction($value) {
  $is-direction: index((to top, to top right, to right top, to right, to bottom right, to right bottom, to bottom, to bottom left, to left bottom, to left, to left top, to top left), $value);
  $is-angle: type-of($value) == 'number' and index('deg' 'grad' 'turn' 'rad', unit($value));

  @return $is-direction or $is-angle;
}

/// Convert a direction to legacy syntax
/// @param {Keyword | Angle} $value - Value to convert
/// @require {function} is-direction
/// @require {function} convert-angle
@function legacy-direction($value) {
  @if is-direction($value) == false {
    @warn "Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be an angle or a direction";
  }

  $conversion-map: (
    to top          : bottom,
    to top right    : bottom left,
    to right top    : left bottom,
    to right        : left,
    to bottom right : top left,
    to right bottom : left top,
    to bottom       : top,
    to bottom left  : top right,
    to left bottom  : right top,
    to left         : right,
    to left top     : right bottom,
    to top left     : bottom right
  );

  @if map-has-key($conversion-map, $value) {
    @return map-get($conversion-map, $value);
  }

  @return 90deg - convert-angle($value, 'deg');
}

/// Mixin printing a linear-gradient
/// as well as a plain color fallback
/// and the `-webkit-` prefixed declaration
/// @access public
/// @param {String | List | Angle} $direction - Linear gradient direction
/// @param {Arglist} $color-stops - List of color-stops composing the gradient
@mixin linear-gradient($direction, $color-stops...) {
  @if is-direction($direction) == false {
    $color-stops: ($direction, $color-stops);
    $direction: 180deg;
  }

  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
  background: linear-gradient($direction, $color-stops);
}

/*
.test-1 {
  @include linear-gradient(#31B7D7, #EDAC7D);
}

.test-2 {
  @include linear-gradient(to right, #E47D7D 0%, #C195D3 50%, #4FB4E8 100%);
}

.test-3 {
  @include linear-gradient(42deg, #B58234 0%, #D2B545 50%, #D7C04D 50.01%, #FFFFFF 100%);
}
*/

// -------------------------------------
//   HACKS
// -------------------------------------

*, *:before, *:after {
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}

* {
    outline: 0;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.slick-slide:focus, a {
   outline: none !important;
}

*:focus {
    outline: 0;
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
}


iframe[name='google_conversion_frame'] {
    height: 0 !important;
    width: 0 !important;
    line-height: 0 !important;
    font-size: 0 !important;
    margin-top: -13px;
    float: left;
}

button:hover, button:focus, .button:hover, .button:focus{outline-style: none;}
