// -------------------------------------
//   RODAPÉ
// -------------------------------------

footer {
    background-color: #38427A;
}

.has-btn-fixed {
    padding-bottom: 90px;
}

.footer-menu {
    position: relative;
    background-color: #DDE2F8;
    z-index: 300;
}

.menu-light {
    .menu-logo {
        top: 50%;
        transform: translateY(-50%);
    }
    .menu-nav {
        text-align: right;
        padding: 27.5px 0;
        @include breakpoint(large) {
            padding: 33px 0;
        }
        @include breakpoint(xlarge) {
            padding: 60px 0;
        }
        .custom-menu-class {
            > ul {
                > li {
                    color: $darkblue;
                    > a {
                        color: $darkblue;
                    }
                }
            }
        }
        .menu-item-has-children {
            > a {
                &:after {
                    @include no-svg(icon-dropdown-darkblue);
                }
            }
        }
    }
    .menu-btn-mobile {
        .btn-label {
            color: $darkblue;
        }
    }
    .hamburger.is-active .hamburger-inner,
    .hamburger.is-active .hamburger-inner::before,
    .hamburger.is-active .hamburger-inner::after {
        background-color: $darkblue;
    }
    .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
        background-color: $darkblue;
    }

    .menu-mobile {
        background-color: #DDE2F8;
        border-top: 1px solid darken(#DDE2F8,10%);
        border-bottom: 1px solid darken(#DDE2F8,10%);
        ul {
            background-color: #DDE2F8;
        }
        li {
            color: $darkblue;
            border-bottom: 1px solid darken(#DDE2F8,10%);
            border-right: 1px solid darken(#DDE2F8,10%);
            @include breakpoint(small) {
                font-size: 20px;
            }
            a {
                color: $darkblue;
                &:hover {
                    background-color: darken(#DDE2F8,10%);
                }
            }
        }
        .sub-menu {
            border-left: 1px solid darken(#DDE2F8,15%);
            background-color: darken(#DDE2F8,10%);
            li {
                border-color: darken(#DDE2F8,15%);
            }
            a {
                &:hover {
                    background-color: darken(#DDE2F8,15%);
                }
            }
        }
    }
}

.footer-social {
    background-color: #C7CDEA;
    padding: 40px 0;
    text-align: center;
}

.social-block {
    font-size: 0;
}

.social-title {
    display: block;
    margin-bottom: 30px;
    @include breakpoint(xsmall) {
        margin-bottom: 0;
        display: inline-block;
        vertical-align: middle;
        max-width: calc(100% - 270px);
        text-align: left;
        padding-right: 30px;
    }
    @include breakpoint(medium) {
        padding-right: 40px;
    }
}

.social-links {
    @include breakpoint(xsmall) {
        display: inline-block;
        vertical-align: middle;
        max-width: 270px;
    }
}

.footer-address {
    background-color: $darkblue;
}

.address-title {
    display: block;
    margin-bottom: 40px;
    text-align: center;
    color: #71D0ED;
}

.address-list {
    @include clearfix;
    font-size: 0;
    text-align: center;
    margin-bottom: -40px;
    .address-item {
        margin-bottom: 40px;
        @include breakpoint(xsmall) {
            display: inline-block;
            vertical-align: top;
            margin-right: 30px;
            width: calc(33.33% - (60px / 3));
            &:nth-child(2n) {
                margin-right: 30px;
            }
            &:nth-child(3n) {
                margin-right: 0;
            }
        }
        @include breakpoint(xlarge) {
            width: calc(25% - (90px / 4));
            &:nth-child(2n) {
                margin-right: 30px;
            }
            &:nth-child(3n) {
                margin-right: 30px;
            }
            &:nth-child(4n) {
                margin-right: 0;
            }
        }
        &:last-of-type {
            margin-right: 0;
        }
    }
}

.address-item {
    .item-title {
        margin-bottom: 10px;
    }
    .item-text {
        p, li {
            font-size: 16px;
        }
    }
    .item-align {
        text-align: center;
        @include breakpoint(xsmall) {
            display: inline-block;
            text-align: left;
        }
    }
}

.footer-dev {
    padding: 20px;
    background-color: #38427A;
    text-align: center;
    font-size: 15px;
    color: #fff;
    .dev-style {
        @include font-type(font400);
        position: relative;
        display: inline-block;
        line-height: 1.5;
        cursor: pointer;
        color: #fff;
        transition: $t-color;
        &:hover {
            color: $cyan;
            a {
                color: $cyan;
            }
        }
        &:active {
            top: 2px;
        }
    }
    a {
        color: #fff;
        transition: $t-color;
        &:hover {
            color: $cyan;
        }
    }
    .dev-style a {
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    .footer-pp {
        @include breakpoint(small down) {
            margin-top: 10px;
        }
    }
    > * {
        display: block;
        @include breakpoint(xsmall) {
            display: inline-block;
            vertical-align: middle;
        }
        &:before {
            content: '•';
            display: none;
            margin: 0 15px;
            @include breakpoint(xsmall) {
                display: inline-block;
                vertical-align: middle;
            }
        }
        &:first-child {
            &:before {
                display: none;
            }
        }
    }
}

.footer-selo {
    background-color: #fff;
    padding: 10px 0;
    .selo-list {
        text-align: center;
    }
    .selo-item {
        display: inline-block;
        vertical-align: middle;
        margin: 10px 15px;
        img {
            max-width: 120px;
            max-height: 120px;
            width: 100%;
            margin: 0 auto;
        }
    }
}

// -------------------------------------
// #FIXED BUTTON
// -------------------------------------

.btn-fixed-block {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px 0;
    z-index: 999;
    background-color: rgba(#fff,0.9);
    backdrop-filter: blur(5px);
    border-top: 1px solid $lightblue;
    .btn-list {
        text-align: center;
        font-size: 0;
        margin: 0;
        list-style: none;
    }
    li {
        display: inline-block;
        vertical-align: top;
        margin-right: 10px;
        @include breakpoint(small) {
            margin-right: 20px;
        }
        &:last-of-type {
            margin-right: 0;
        }
    }
    a {
        line-height: 50px;
        padding: 0 30px;
    }
    span {
        display: inline-block;
        vertical-align: middle;
    }
    @include breakpoint(medium down) {
        .btn-contact {
            max-width: calc(100% - 120px);
            a {
                max-width: 100%;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
        .btn-whasapp, .btn-phone {
            a {
                font-size: 0;
                border-radius: 50%;
                width: 50px;
                padding: 0;
            }
            img {
                margin-right: 0;
            }
        }
    }
}
