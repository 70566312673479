// -------------------------------------
// #TOP
// -------------------------------------

.top-block, .banner-slider-block {
    position: relative;
    overflow: hidden;
    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 100%;
        height: 40px;
        transform: translate(-50%, 50%);
        background-color: #fff;
        border-radius: 50%;
        z-index: 30;
        @include breakpoint(small) {
            height: 60px;
        }
        @include breakpoint(medium) {
            height: 80px;
        }
        @include breakpoint(large) {
            height: 100px;
        }
        @include breakpoint(xlarge) {
            height: 160px;
        }
    }
}

.top-img {
    display: block;
    position: relative;
    background-color: $lightgray;
    @include maintain-aspect-ratio(8,5);
    @include breakpoint(801px) {
        @include maintain-aspect-ratio(16,5);
    }
    img {
        width: 100%;
    }
}

.no-picture {
    .top-img {
        @include maintain-aspect-ratio(16,5);
    }
}

// -------------------------------------
// #TITLE
// -------------------------------------

.title-block {
    text-align: center;
    margin-top: $spacing-sm;
    @include breakpoint(small) {
        margin-top: $spacing-md;
    }
    @include breakpoint(large) {
        margin-top: $spacing-lg;
    }
    @include breakpoint(xlarge) {
        margin-top: 160px;
    }
    @include breakpoint(wrapper) {
        margin-top: 190px;
    }
}

.top-block + .title-block {
    margin-top: 40px;
    @include breakpoint(medium) {
        margin-top: 20px;
    }
    @include breakpoint(xlarge) {
        margin-top: 0;
    }
}

.title-date {
    display: block;
    margin-bottom: 10px;
    color: $cyan;
    font-size: 18px;
}

.title-desc {
    margin-top: 10px;
    p, li {
        font-size: 20px;
    }
}

// -------------------------------------
// #VIEW
// -------------------------------------

.view-content {
    @include article(alt400, $black, font400, $black, $cyan, rgba($cyan,0.7));
}

// -------------------------------------
// #TRATAMENTO
// -------------------------------------

.shadow-style {
    border-radius: $border-radius;
    box-shadow: 0 0 30px rgba(#000,0.15);
    background-color: #fff;
    overflow: hidden;
}

.tratamento-list {
    @include clearfix;
    font-size: 0;
    margin-bottom: -50px;
    &:before, &:after {
        width: 0;
    }
    .tratamento-item {
        margin-bottom: 50px;
        @include breakpoint(xsmall) {
            display: inline-block;
            vertical-align: top;
            margin-right: 20px;
            width: calc(50% - (20px / 2));
            &:nth-child(2n) {
                margin-right: 0;
            }
        }
        @include breakpoint(medium) {
            margin-right: 50px;
            width: calc(50% - (50px / 2));
            &:nth-child(2n) {
                margin-right: 0;
            }
        }
        @include breakpoint(large) {
            width: calc(33.33% - (100px / 3));
            &:nth-child(2n) {
                margin-right: 50px;
            }
            &:nth-child(3n) {
                margin-right: 0;
            }
        }
        &:last-of-type {
            margin-right: 0;
        }
    }
}

.tratamento-style {
    position: relative;
    transition: $t-transform;
    &:hover {
        transform: translateY(-10px);
    }
    &:active {
        transition-duration: .1s;
        transform: translateY(0);
    }
    .item-content {
        text-align: center;
        padding: 20px;
    }
    .item-title {
        transition: $t-color;
    }
    a {
        display: block;
        transition: $t-bg-color;
        &:hover {
            background-color: $darkblue;
            .item-title {
                color: #fff;
            }
        }
    }
}

// -------------------------------------
// #CONVENIOS
// -------------------------------------

.convenio-list {
    @include clearfix;
    font-size: 0;
    text-align: center;
    margin-bottom: -10px;
    @include breakpoint(small) {
        margin-bottom: -20px;
    }
    .convenio-item {
        display: inline-block;
        vertical-align: top;
        margin-bottom: 10px;
        margin-right: 10px;
        width: calc(50% - (10px / 2));
        &:nth-child(2n) {
            margin-right: 0;
        }
        @include breakpoint(xsmall) {
            margin-bottom: 20px;
            margin-right: 20px;
            width: calc(33.33% - (40px / 3));
            &:nth-child(2n) {
                margin-right: 20px;
            }
            &:nth-child(3n) {
                margin-right: 0;
            }
        }
        @include breakpoint(medium) {
            width: calc(25% - (60px / 4));
            &:nth-child(2n) {
                margin-right: 20px;
            }
            &:nth-child(3n) {
                margin-right: 20px;
            }
            &:nth-child(4n) {
                margin-right: 0;
            }
        }
        @include breakpoint(large) {
            width: calc(20% - (80px / 5));
            &:nth-child(2n) {
                margin-right: 20px;
            }
            &:nth-child(3n) {
                margin-right: 20px;
            }
            &:nth-child(4n) {
                margin-right: 20px;
            }
            &:nth-child(5n) {
                margin-right: 0;
            }
        }
        &:last-of-type {
            margin-right: 0;
        }
    }
}

.convenio-style {
    position: relative;
    @include maintain-aspect-ratio(1,1);
    transition: $t-box-shadow, $t-border-color;
    border: 1px solid #fff;
    &:hover {
        box-shadow: none;
        border-color: #D7DBF0;
    }
    &:active {
        top: 2px;
    }
    img, span {
        position: absolute;
        padding: 20px;
        max-width: 100%;
        max-height: 100%;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
    }
    span {
        @include font-type(font400);
        right: auto;
        bottom: auto;
        color: $gray;
        width: 100%;
        @include word-wrap;
        text-align: center;
        font-size: 18px;
        @include breakpoint(small) {
            font-size: 20px;
        }
        @include breakpoint(large) {
            font-size: 24px;
        }
    }
}

// -------------------------------------
// #DEPOIMENTOS
// -------------------------------------

.depoimento-style {
    position: relative;
    z-index: 10;
    &:before {
        content: '';
        position: absolute;
        top: 10px;
        left: 10px;
        width: 100px;
        height: 75px;
        @include no-svg(icon-quote);
        z-index: -1;
    }
    .item-name {
        margin-top: 10px;
        font-size: 18px;
    }
}

.depoimento-list {
    position: relative;
    font-size: 0;
}

.depoimento-content {
    padding: 20px;
    @include breakpoint(small) {
        padding: 40px;
    }
}

.cssgrid {
    .depoimento-list {
        display: grid;
        grid-template-columns: repeat(1, minmax(100px,1fr));
        grid-gap: 20px;
        grid-auto-rows: 0;
        @include breakpoint(medium) {
            grid-template-columns: repeat(2, minmax(100px,1fr));
        }
    }
    .depoimento-content {
        padding: 20px 20px 15px 20px;
        @include breakpoint(small) {
            padding: 40px 40px 35px 40px;
        }
    }
}

html:not(.cssgrid) {
    .depoimento-list {
        .depoimento-item {
            margin-bottom: 20px;
            @include breakpoint(medium) {
                display: inline-block;
                vertical-align: top;
                margin-right: 20px;
                width: calc(50% - 10px);
                &:nth-child(2n) {
                    margin-right: 0;
                }
            }
        }
    }
    &.flexbox {
        @include breakpoint(medium) {
            .depoimento-list {
                display: flex;
                flex-wrap: wrap;
                .depoimento-item {
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;
                }
            }
        }
    }
}

// -------------------------------------
// #BLOG
// -------------------------------------

.side-column-block {
    margin: 0 0 60px 0;
    @include breakpoint(medium) {
        @include span(12 of 12);
    }
    @include breakpoint(large) {
        @include span(2 of 6 last);
        margin: 0;
    }
    @include breakpoint(xlarge) {
        @include span(3 of 10 last);
    }
}

.main-column-block {
    @include breakpoint(medium) {
        @include span(10 of 12);
        @include push(1 of 12);
    }
    @include breakpoint(large) {
        @include span(4 of 6);
        @include push(0 of 6);
    }
    @include breakpoint(xlarge) {
        @include span(6 of 10);
    }
    .pagination {
        text-align: center;
        @include breakpoint(large) {
            text-align: left;
        }
    }
    &:only-child {
        @include breakpoint(medium) {
            @include span(10 of 12);
            @include push(1 of 12);
        }
        @include breakpoint(large) {
            @include span(8 of 10);
            @include push(1 of 10);
        }
        @include breakpoint(xlarge) {
            @include span(6 of 10);
            @include push(2 of 10);
        }
    }
}

.cat-style {
    text-align: center;
    border-radius: $border-radius;
    @include breakpoint(large) {
        background-color: $lightcyan;
        text-align: left;
        padding: 30px;
    }
    @include breakpoint(xlarge) {
        padding: 40px;
    }
    button {
        position: relative;
        cursor: pointer;
        padding: 15px 20px;
        background-color: $lightcyan;
        width: 100%;
        line-height: 1.25;
        display: block;
        font-size: 18px;
        border-radius: 50px;
        z-index: 10;
        transition: $t-bg-color, $t-color;
        @include breakpoint(small) {
            font-size: 20px;
        }
        @include breakpoint(large) {
            display: none;
        }
        span {
            position: relative;
            display: inline-block;
            padding-right: 30px;
            &:after {
                content: '';
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                width: 18px;
                height: 10.5px;
                @include no-svg(icon-dropdown-darkblue);
                transition: $t-transform, $t-filter;
            }
        }
        &:hover {
            color: #fff;
            background-color: $cyan;
            span {
                &:after {
                    filter: brightness(0) invert(1);
                }
            }
        }
        &.is-active {
            background-color: $cyan;
            color: #fff;
            span {
                &:after {
                    transform: translateY(-50%) rotate(180deg);
                    filter: brightness(0) invert(1);
                }
            }
        }
    }
    .cat-title {
        padding-bottom: 25px;
        border-bottom: 1px solid #fff;
        display: none;
        @include breakpoint(large) {
            display: block;
        }
    }
    ul {
        position: relative;
        background-color: $lightcyan;
        list-style: none;
        margin: 0;
        border-radius: 0 0 $border-radius $border-radius;
        padding: 57px 20px 30px 20px;
        top: -27px;
        display: none;
        @include breakpoint(large) {
            display: block !important;
            border-top: none;
            padding: 25px 0 0 0;
            top: 0;
        }
    }
    li {
        display: block;
        @include font-type(font500);
        font-size: 20px;
        line-height: $line-height-md;
        color: $darkblue;
        margin-bottom: 20px;
        @include breakpoint(large) {
            margin-bottom: 25px;
        }
        &:last-of-type {
            margin-bottom: 0;
        }
        a {
            position: relative;
            color: $darkblue;
            transition: $t-color;
            &:hover {
                color: $cyan;
            }
            &:active {
                top: 2px;
            }
        }
    }
    .current-cat {
        @include font-type(font700);
    }
}

.blog-style {
    .item-img {
        margin-bottom: 15px;
        @include breakpoint(small) {
            margin-bottom: 0;
        }
    }
    .item-content {
        .item-date {
            display: block;
            margin-bottom: 10px;
            font-size: 16px;
            color: $gray;
        }
        .r3-link {
            margin-top: 10px;
        }
    }
}

.blog-list {
    .blog-item {
        text-align: left;
        font-size: 0;
        margin-bottom: 50px;
        &:last-of-type {
            margin-bottom: 0;
        }
        .item-img {
            @include breakpoint(small) {
                display: inline-block;
                vertical-align: top;
                width: 35%;
            }
        }
        .item-content {
            @include breakpoint(small) {
                display: inline-block;
                vertical-align: top;
                width: 65%;
                padding-left: 20px;
            }
            @include breakpoint(xsmall) {
                padding-left: 30px;
            }
            @include breakpoint(xlarge) {
                padding-left: 50px;
            }
        }
    }
}

.post-block {
    margin-top: $spacing-sm;
    @include breakpoint(small) {
        margin-top: $spacing-md;
    }
    @include breakpoint(medium) {
        @include span(12 of 12);
    }
    @include breakpoint(xlarge) {
        @include span(10 of 12);
        @include push(1 of 12);
    }
}

.tags-list {
    @include clearfix;
    text-align: center;
    margin: 0;
    font-size: 0;
    list-style: none;
    margin-bottom: -10px;
    @include breakpoint(xsmall) {
        margin-top: -5px;
        margin-bottom: -5px;
    }
    @include breakpoint(large) {
        margin-top: -10px;
        margin-bottom: -10px;
    }
    li {
        @include font-type(font400);
        font-size: 18px;
        line-height: $line-height-md;
        color: $darkblue;
        display: block;
        margin-bottom: 10px;
        @include breakpoint(xsmall) {
            display: inline-block;
            vertical-align: top;
            margin: 5px;
        }
        @include breakpoint(large) {
            margin: 10px;
        }
        &:last-of-type {
            margin-right: 0;
        }
    }
    a {
        position: relative;
        display: block;
        color: $darkblue;
        background-color: $lightblue;
        transition: $t-color, $t-bg-color;
        border-radius: 50px;
        padding: 10px 20px;
        @include breakpoint(medium) {
            padding: 10px 30px;
        }
        &:active {
            top: 2px;
        }
        &:focus {
            color: $darkblue;
        }
        &:hover {
            background-color: $cyan;
            color: #fff;
        }
    }
}

// -------------------------------------
// #SEARCH
// -------------------------------------

.search-element {
    display: block;
    margin-bottom: $spacing-sm;
    .search-style {
        position: relative;
        background-color: $lightcyan;
        border-radius: 15px;
        font-size: 0;
        padding-right: 20px;
        transition: $t-box-shadow;
        @include breakpoint(xsmall) {
            padding-right: 30px;
        }
        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: calc(100% + 10px);
            height: calc(100% + 10px);
            transform: translate(-50%,-50%);
            border: 1px solid rgba(#fff,0.5);
            border-radius: 50px;
            z-index: -1;
            transition: $t-border-color, $t-bg-color;
        }
        &:focus-within {
            &:before {
                border-color: #fff;
                background-color: rgba(#fff,0.3);
            }
        }
        input {
            @include font-type(font400);
            display: inline-block;
            font-size: 20px;
            vertical-align: middle;
            height: 60px;
            margin: 0;
            padding-left: 20px;
            border-radius: 50px;
            width: calc(100% - 45px);
            background-color: $lightcyan;
            margin-right: 15px;
            color: $darkblue;
            @include placeholder-color($darkblue);
            @include breakpoint(xsmall) {
                padding: 0 20px 0 30px;
            }
        }
        .search-submit {
            display: inline-block;
            vertical-align: middle;
            cursor: pointer;
            transition: $t-opacity;
            &:hover {
                opacity: 0.5;
            }
            img {
                width: 30px;
                height: 30px;
            }
        }
    }
}

.search-list {
    .search-item {
        margin-bottom: 50px;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

.search-item {
    .item-text {
        margin-top: 10px;
    }
}

// -------------------------------------
// #SHARE POST
// -------------------------------------

.share-block {
    text-align: center;
    margin-top: 40px;
    .share-title {
        @include font-type(font500);
        color: $darkblue;
        display: block;
        font-size: 20px;
        margin-bottom: 20px;
        @include breakpoint(small) {
            display: inline-block;
            vertical-align: middle;
            margin-bottom: 0;
            margin-right: 20px;
        }
    }
    .a2a_kit {
        font-size: 0;
        display: inline-block;
        vertical-align: middle;
    }
    .a2a_default_style a {
        padding: 0;
        margin-right: 10px;
        &:last-child {
            margin-right: 0;
        }
        .a2a_svg {
            background-color: $cyan !important;
            transition: $t-bg-color;
            border-radius: 0;
            padding: 2px;
        }
    }
    .a2a_default_style a:hover .a2a_svg {
        opacity: 1;
        background-color: darken($cyan,10%) !important;
    }
}

// -------------------------------------
// #RELATED
// -------------------------------------

.related-title {
    text-align: center;
    margin-bottom: 60px;
    @include breakpoint(medium) {
        @include span(12 of 12);
    }
}

.related-block {
    border-top: 1px solid #D6D9EB;
    @include clearfix;
    .blog-list {
        text-align: center;
        font-size: 0;
        @include clearfix;
        @include breakpoint(medium) {
            @include span(10 of 12);
            @include push(1 of 12);
        }
        @include breakpoint(large) {
            @include span(8 of 12);
            @include push(2 of 12);
        }
        @include breakpoint(xlarge) {
            @include span(12 of 12);
            @include push(0 of 12);
        }
        .blog-item {
            @include breakpoint(xlarge) {
                display: inline-block;
                vertical-align: top;
                margin-right: 20px;
                margin-bottom: 0;
                width: calc(25% - 15px);
                &:nth-child(4n) {
                    margin-right: 0;
                }
            }
            &:last-of-type {
                margin-right: 0;
            }
            .item-img {
                @include breakpoint(xlarge) {
                    margin-bottom: 20px;
                }
            }
            .item-img, .item-content {
                @include breakpoint(xlarge) {
                    display: block;
                    width: 100%;
                }
            }
            .item-content {
                @include breakpoint(xlarge) {
                    padding-left: 0;
                }
            }
        }
    }
}

.related-btn {
    text-align: center;
    margin-top: $spacing-sm;
    @include breakpoint(medium) {
        @include span(12 of 12);
    }
}

// -------------------------------------
// #HOME
// -------------------------------------

.slick-dots {
    @include clearfix;
    z-index: 99;
    text-align: center;
    width: 100%;
    pointer-events: none;
    margin: 0;
    font-size: 0;
    margin-top: -7px;
    margin-bottom: -7px;
    @include breakpoint(xsmall) {
        margin-top: -10px;
        margin-bottom: -10px;
    }
    li {
        cursor: pointer;
        pointer-events: auto;
        width: 16px;
        height: 16px;
        margin: 7px;
        border-radius: 50%;
        display: inline-block;
        background-color: #fff;
        border: 3px solid $darkblue;
        transition: $t-border-color, $t-bg-color;
        box-shadow: 0 0 0 2px rgba(#fff,0.3);
        @include breakpoint(xsmall) {
            margin: 10px;
        }
        &:hover {
            background-color: $cyan;
        }
        &.slick-active {
            background-color: $darkblue;
        }
    }
}

.banner-slider-item {
    display: block;
    position: relative;
    background-color: $lightgray;
    @include maintain-aspect-ratio(4,3);
    @include breakpoint(801px) {
        @include maintain-aspect-ratio(16,6);
    }
    img {
        width: 100%;
    }
}

.no-picture {
    .banner-slider-item {
        @include maintain-aspect-ratio(16,6);
    }
}

.banner-slider-list {
    position: relative;
    font-size: 0;
    .slick-dots {
        position: absolute;
        left: 0;
        bottom: 40px;
        @include breakpoint(small) {
            bottom: 50px;
        }
        @include breakpoint(medium) {
            bottom: 60px;
        }
        @include breakpoint(large) {
            bottom: 70px;
        }
        @include breakpoint(xlarge) {
            bottom: 100px;
        }
        li {
            background-color: $darkblue;
            &:hover {
                background-color: $cyan;
            }
            &.slick-active {
                background-color: #fff;
            }
        }
    }
}

.tratamento-slider-title {
    text-align: center;
    margin-bottom: $spacing-sm;
    @include breakpoint(small) {
        margin-bottom: $spacing-md;
    }
}

.tratamento-slider-block {
    overflow: hidden;
    padding: $spacing-sm 0;
    @include breakpoint(small) {
        padding: $spacing-md 0;
    }
    @include breakpoint(large) {
        padding: $spacing-lg 0;
    }
    @include breakpoint(xlarge) {
        padding-top: 160px;
    }
    @include breakpoint(wrapper) {
        padding-top: 190px;
    }
}

.banner-slider-block + .tratamento-slider-block {
    padding-top: 40px;
    @include breakpoint(medium) {
        padding-top: 20px;
    }
    @include breakpoint(xlarge) {
        padding-top: 0;
    }
}

.tratamento-slider-list {
    .slick-list {
        overflow: visible;
        font-size: 0;
    }
    .slick-track {
        margin: 0;
    }
    .slick-slide {
        margin: 0 10px;
        @include breakpoint(medium) {
            margin: 0 20px;
        }
    }
    .slick-dots {
        margin-top: 50px;
    }
}

.location-slider-block {
    position: relative;
    overflow: hidden;
    padding: 0 0 30px 0;
    @include breakpoint(xsmall) {
        padding: 30px 0;
    }
}

.location-slider-block + .related-block {
    margin-top: $spacing-sm;
    @include breakpoint(small) {
        margin-top: $spacing-md;
    }
    @include breakpoint(large) {
        margin-top: $spacing-lg;
    }
}

.location-slider-wrap {
    font-size: 0;
}

.location-slider-content {
    position: relative;
    padding: 40px 0 50px 0;
    text-align: center;
    @include breakpoint(xsmall) {
        display: inline-block;
        vertical-align: middle;
        width: 48%;
        text-align: left;
    }
    &:before, &:after {
        content: '';
        position: absolute;
        top: 0;
        width: 100vw;
        height: 100%;
        background-color: $lightcyan;
        z-index: -1;
    }
    &:before {
        right: 100%;
    }
    &:after {
        left: 0;
    }
    .item-text {
        margin-top: 25px;
        p, li {
            font-size: 20px;
        }
    }
}

.location-slider-arrows {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    transform: translateY(50%);
    @include breakpoint(xsmall) {
        display: inline-block;
        vertical-align: middle;
        width: 48%;
    }
    .slick-prev, .slick-next {
        cursor: pointer;
        display: inline-block;
        vertical-align: middle;
        height: 50px;
        width: 50px;
        background-color: $darkblue;
        border-radius: 50%;
        @include no-svg(icon-arrow-white);
        background-size: 24px;
        background-position: center center;
        transition: $t-bg-color, $t-transform;
        &:hover {
            background-color: $cyan;
        }
        &:active {
            transition-duration: .1s;
        }
    }
    .slick-prev {
        transform: rotate(180deg);
        &:active {
            transform: translateX(-5px) rotate(180deg);
        }
    }
    .slick-next {
        margin-left: 30px;
        &:active {
            transform: translateX(5px);
        }
    }
}

.location-slider-list {
    margin-top: $spacing-sm;
    @include breakpoint(xsmall) {
        padding-bottom: 0;
        margin-top: 0;
        display: inline-block !important;
        vertical-align: middle;
        width: 52%;
        padding-left: 6%;
    }
    .slick-list {
        margin: -30px;
    }
    .slick-slide {
        margin: 30px;
    }
}

.depoimento-slider-title {
    margin-bottom: 40px;
    text-align: center;
    h2 {
        position: relative;
        display: inline;
        &:before, &:after {
            content: '';
            position: absolute;
            width: 40px;
            height: 30px;
            @include no-svg(icon-quote);
            z-index: -1;
            @include breakpoint(small) {
                width: 80px;
                height: 60px;
            }
            @include breakpoint(large) {
                width: 100px;
                height: 75px;
            }
        }
        &:before {
            top: -10px;
            left: -10px;
            @include breakpoint(small) {
                top: -20px;
                left: -20px;
            }
            @include breakpoint(large) {
                top: -30px;
                left: -30px;
            }
        }
        &:after {
            bottom: -10px;
            right: -10px;
            transform: rotate(180deg);
            @include breakpoint(small) {
                bottom: -20px;
                right: -20px;
            }
            @include breakpoint(large) {
                bottom: -30px;
                right: -30px;
            }
        }
    }
}

.depoimento-slider-list {
    .depoimento-content {
        text-align: center;
        padding: 0;
        .item-text {
            p, li {
                @include breakpoint(small) {
                    font-size: 20px;
                }
            }
        }
        .item-name {
            margin-top: 30px;
        }
    }
    .slick-dots {
        margin-top: 30px;
        li {
            border-color: $cyan;
            &:hover {
                background-color: $lightcyan;
            }
            &.slick-active {
                background-color: $cyan;
            }
        }
    }
}

// -------------------------------------
// #CTA
// -------------------------------------

.cta-block {
    position: relative;
    background-color: $lightblue;
    overflow: hidden;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 200px;
        background-color: #fff;
        @include breakpoint(small) {
            height: 300px;
        }
        @include breakpoint(large) {
            height: 50px;
        }
    }
}

.cta-img, .cta-content {
    position: relative;
    z-index: 10;
}

.cta-img {
    overflow: hidden;
    height: 200px;
    @include breakpoint(small) {
        height: 300px;
    }
    @include breakpoint(large) {
        height: auto;
        overflow: visible;
        @include span(5 of 12 last);
    }
    @include breakpoint(xlarge) {
        @include span(4 of 10 last);
    }
    @include breakpoint(wrapper) {
        @include span(4 of 10 last);
    }
    img {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        max-width: 200px;
        @include breakpoint(small) {
            max-width: 300px;
        }
        @include breakpoint(large) {
            max-width: 100%;
        }
    }
}

.cta-content {
    padding: $spacing-sm 0;
    text-align: center;
    @include breakpoint(small) {
        padding: $spacing-md 0;
    }
    @include breakpoint(large) {
        padding: 130px 0 $spacing-md 0;
        @include span(7 of 12);
        text-align: left;
    }
    @include breakpoint(xlarge) {
        @include span(6 of 10);
    }
    @include breakpoint(wrapper) {
        @include span(5 of 10);
        @include push(1 of 10);
    }
}

.cta-text {
    margin-top: 20px;
    p, li {
        font-size: 20px;
    }
}

.cta-btn {
    @include clearfix;
    margin-top: 20px;
    margin-bottom: -20px;
    a {
        min-width: 240px;
        @include breakpoint(xsmall) {
            min-width: auto;
        }
    }
    .btn-first, .btn-last {
        display: block;
        margin: 0 0 20px 0;
        @include breakpoint(xsmall) {
            display: inline-block;
            vertical-align: top;
            margin: 0 20px 20px 0;
            &:last-of-type {
                margin-right: 0;
            }
        }
    }
}

.cta-link {
    margin-top: 20px;
    a {
        @include font-type(font400);
        position: relative;
        display: inline-block;
        font-size: 18px;
        line-height: 1.5;
        color: $darkblue;
        text-decoration: underline;
        transition: $t-color;
        &:focus {
            color: $darkblue;
        }
        &:hover {
            color: $cyan;
        }
        &:active {
            top: 2px;
        }
    }
}

// -------------------------------------
// #CONTATO
// -------------------------------------

.form-style {
    font-size: 0;
    .form-field, .form-info {
        margin-bottom: 20px;
    }
    .field-2 {
        @include breakpoint(xsmall) {
            display: inline-block;
            vertical-align: top;
            width: calc(50% - (20px / 2));
            margin-left: 20px;
        }
    }
    .field-3 {
        @include breakpoint(medium) {
            display: inline-block;
            vertical-align: top;
            width: calc(33.33% - (40px / 3));
            margin-left: 20px;
        }
    }
    .field-first {
        margin-left: 0;
    }
    .form-submit {
        position: relative;
        padding-top: 10px;
        text-align: center;
        .ajax-loader, .wpcf7-spinner {
            position: absolute;
            top: 70px;
            left: 50%;
            margin: 0;
            transform: translateX(-50%);
        }
    }
    .form-info {
        text-align: center;
        p {
            font-size: 16px;
            a {
                text-decoration: underline;
            }
        }
    }
    label {
        @include font-type(font500);
        font-size: 20px;
        color: $darkblue;
        line-height: 1.5;
        margin-bottom: 8px;
    }
    input:not([type=submit]), textarea, select {
        @include font-type(font400);
        font-size: 18px;
        padding: 10px 20px;
        margin: 0;
        border-radius: $border-radius;
        color: $black;
        background-color: $lightcyan;
        border: 1px solid $lightcyan;
        transition: $t-border-color;
        &:focus {
            border: 1px solid $cyan;
            background-color: $lightcyan;
        }
    }
    input[type=date] {
        line-height: 40px;
    }
    select {
        padding-right: 30px;
        option {
            background-color: #fff;
        }
    }
    input:not([type=submit]), select {
        height: 60px;
    }
    input[type=submit] {
        position: relative;
        cursor: pointer;
        @include font-type(font400);
        margin: 0;
        border: 0;
        text-align: center;
        padding: 15px 20px;
        width: 100%;
        border-radius: 50px;
        font-size: 20px;
        color: #fff;
        background-color: $darkblue;
        box-shadow: 0 0 30px rgba(#000,0.15);
        transition: $t-bg-color;
        @include breakpoint(xsmall) {
            width: auto;
            padding: 15px 50px;
        }
        &:hover {
            background-color: $cyan;
        }
        &:active {
            top: 2px;
        }
    }
    span[role=alert], .wpcf7-not-valid-tip {
        @include font-type(font400);
        font-size: 16px;
        color: $red;
        display: inline-block;
        margin-top: 10px;
        position: relative;
        padding-left: 28px;
        &:before {
            content: "!";
            position:absolute;
            @include font-type(font400);
            top: 50%;
            left: 0;
            text-align: center;
            font-size: 16px;
            border-radius: 50%;
            transform: translateY(-50%);
            background-color: $red;
            color: #fff;
            width: 20px;
            height: 20px;
        }
    }
    p:empty {
        display: none;
    }
}

// -------------------------------------
// #LOCATION
// -------------------------------------

.location-block {
    position: relative;
    overflow: hidden;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        width: 100%;
        height: 40px;
        transform: translateX(-50%);
        background-color: $lightblue;
        border-radius: 50%;
        z-index: 5;
        @include breakpoint(small) {
            height: 60px;
        }
        @include breakpoint(medium) {
            height: 80px;
        }
        @include breakpoint(large) {
            height: 100px;
        }
        @include breakpoint(xlarge) {
            height: 160px;
        }
    }
    &:after {
        content: '';
        position: absolute;
        top: 20px;
        left: 0;
        width: 100%;
        height: 400px;
        @include linear-gradient(to bottom, $lightblue 100px, rgba($lightblue,0) 100%);
        z-index: 10;
        @include breakpoint(small) {
            top: 30px;
        }
        @include breakpoint(medium) {
            top: 40px;
        }
        @include breakpoint(large) {
            top: 50px;
        }
        @include breakpoint(xlarge) {
            top: 80px;
        }
    }
}

.location-title, .location-list {
    position: relative;
    z-index: 20;
}

.location-title {
    text-align: center;
    margin-bottom: 60px;
}

.location-list {
    @include clearfix;
    font-size: 0;
    text-align: center;
    margin-bottom: -50px;
    &:before, &:after {
        width: 0;
    }
    .location-item {
        margin-bottom: 50px;
        @include breakpoint(xsmall) {
            display: inline-block;
            vertical-align: top;
            margin-right: 20px;
            width: calc(50% - (20px / 2));
            &:nth-child(2n) {
                margin-right: 0;
            }
        }
        @include breakpoint(medium) {
            margin-right: 50px;
            width: calc(50% - (50px / 2));
            &:nth-child(2n) {
                margin-right: 0;
            }
        }
        @include breakpoint(large) {
            width: calc(33.33% - (100px / 3));
            &:nth-child(2n) {
                margin-right: 50px;
            }
            &:nth-child(3n) {
                margin-right: 0;
            }
        }
        &:last-of-type {
            margin-right: 0;
        }
    }
}

.location-style {
    position: relative;
    .item-content {
        padding: 20px;
        @include breakpoint(small) {
            padding: 30px;
        }
    }
    .item-text {
        margin-top: 10px;
    }
}

// -------------------------------------
// #GALLERY
// -------------------------------------

.gallery-block {
    font-size: 0;
    margin-top: 40px;
    margin-bottom: -10px;
    @include breakpoint(small) {
        margin-bottom: -20px;
    }
}

.gallery-item {
    overflow: hidden;
    box-shadow: 0 0 20px rgba(#000,0.1);
    border-radius: $border-radius;
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: calc(50% - 5px);
    margin-right: 10px;
    margin-bottom: 10px;
    &:nth-child(2n) {
        margin-right: 0;
    }
    @include breakpoint(small) {
        width: calc(50% - 10px);
        margin-bottom: 20px;
        margin-right: 20px;
        &:nth-child(2n) {
            margin-right: 0;
        }
    }
    @include breakpoint(xsmall) {
        width: calc(33.33% - 13.33px);
        &:nth-child(2n) {
            margin-right: 20px;
        }
        &:nth-child(3n) {
            margin-right: 0;
        }
    }
    @include breakpoint(medium) {
        width: calc(25% - 15px);
        &:nth-child(2n) {
            margin-right: 20px;
        }
        &:nth-child(3n) {
            margin-right: 20px;
        }
        &:nth-child(4n) {
            margin-right: 0;
        }
    }
    &:last-of-type {
        margin-right: 0;
    }
    img {
        display: block;
        width: 100%;
        max-width: 300px;
        margin: 0 auto;
    }
}

.gallery-item, .blocks-gallery-item, .blog-style .item-img {
    position: relative;
    img {
        transition: $t-transform;
    }
    a {
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $cyan;
            opacity: 0;
            transition: $t-opacity;
            z-index: 2;
        }
        &:hover {
            &:before {
                opacity: 0.5;
            }
        }
        &:active {
            img {
                transition-duration: .1s;
                transform: scale(1.1);
            }
        }
    }
}

.gallery-item, .blocks-gallery-item {
    a {
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%);
            width: 24px;
            height: 24px;
            @include no-svg(icon-zoom);
            opacity: 0;
            transition: $t-opacity;
            z-index: 5;
        }
        &:hover {
            &:after{
                opacity: 1;
            }
        }
    }
}

/* overlay at start */
.mfp-fade.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
/* overlay animate in */
.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}
/* overlay animate out */
.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

/* content at start */
.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
/* content animate it */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}
/* content animate out */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}

// -------------------------------------
// #LAZY LOAD
// -------------------------------------

.lazyload,
.lazyloading {
	opacity: 0;
}
.lazyloaded {
	opacity: 1;
	transition: opacity 300ms;
}

.loader {
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    margin-top: -18px;
    margin-left: -18px;
    border: 3px solid darken($lightgray,15%); /* Circle */
    border-top: 3px solid darken($lightgray,30%); /* Circle Spinner */
    border-radius: 50%;
    width: 36px;
    height: 36px;
    animation: spin 1s linear infinite;
    display: block;
}
.lazyloaded + .loader {
    display: none;
}

.slick-slide {
    .loader {
        display: none;
    }
    .slick-loading + .loader {
        display: block;
    }
}

.placeholder-43, .placeholder-11 {
    position: relative;
    display: block;
    overflow: hidden;
    background-color: $lightgray;
    img {
        width: 100%;
    }
}
.placeholder-43 {
    @include maintain-aspect-ratio(4,3);
}
.placeholder-11 {
    @include maintain-aspect-ratio(1,1);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

// -------------------------------------
// #SOCIAL LINKS
// -------------------------------------

.social-links {
    margin: 0;
    list-style: none;
    font-size: 0;
    li {
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
        @include breakpoint(small) {
            margin-right: 15px;
        }
        &:last-of-type {
            margin-right: 0;
        }
    }
    a {
        position: relative;
        display: inline-block;
        padding: 10px;
        border-radius: 50%;
        background-color: $darkblue;
        transition: $t-bg-color;
        &:hover {
            background-color: $cyan;
        }
        &:active {
            top: 2px;
        }
    }
    img {
        width: 22px;
        height: 22px;
    }
}

// -------------------------------------
// #PAGINAÇÃO
// -------------------------------------

.pagination {
    text-align: center;
    margin-top: $spacing-sm;
    @include breakpoint(small) {
        margin-top: $spacing-md;
    }
    ul {
        display: inline-block;
        margin: 0;
        list-style: none;
    }
    li {
        @include font-type(font400);
        display: inline-block;
        vertical-align: middle;
        margin: 3px;
        text-align: center;
        font-size: 16px;
        @include breakpoint(small) {
            margin: 5px;
            font-size: 18px;
        }
    }
    a, span {
        border-radius: 50%;
        width: 35px;
        line-height: 35px;
        height: 35px;
        background-color: #fff;
        border: 1px solid $darkblue;
        color: $darkblue;
        display: block;
        box-shadow: 0 0 20px rgba(#000,0.1);
        @include breakpoint(small) {
            width: 40px;
            line-height: 40px;
            height: 40px;
        }
    }
    a {
        position: relative;
        transition: $t-color, $t-bg-color, $t-border-color;
        &:hover {
            color: #fff;
            border-color: $cyan;
            background-color: $cyan;
            &.next, &.prev {
                &:before {
                    filter: brightness(0) invert(1);
                }
            }
        }
        &:active {
            top: 2px;
        }
    }
    .next, .prev {
        position: relative;
        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 18px;
            height: 11.25px;
            @include no-svg(icon-arrow-darkblue);
            @include breakpoint(small) {
                width: 20px;
                height: 12.5px;
            }
        }
    }
    .prev {
        &:before {
            transform: translateY(-50%) translateX(-50%) rotate(180deg);
        }
    }
    .current {
        color: #fff;
        background-color: $darkblue;
        border-color: $darkblue;
    }
}
