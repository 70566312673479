.flexbox {
    .menu-mobile {
        ul:not(.sub-menu) {
            display: flex;
            flex-wrap: wrap;
        }
    }
    @include breakpoint(xsmall) {
        .tratamento-list {
            display: flex;
            flex-wrap: wrap;
            .tratamento-item {
                display: inline-flex;
                .tratamento-style {
                    display: flex;
                    height: 100%;
                    flex-grow: 1;
                    flex-direction: column;
                }
                a {
                    flex-grow: 1;
                }
            }
        }
    }
    @include breakpoint(xsmall) {
        .location-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            .location-item {
                display: inline-flex;
                .location-style {
                    display: flex;
                    height: 100%;
                    flex-grow: 1;
                    flex-direction: column;
                }
            }
        }
    }
    .tratamento-slider-list .slick-track {
        display: flex;
        .slick-slide {
            height: inherit;
            > div {
                height: 100%;
            }
        }
        .tratamento-item, .tratamento-style, a {
            height: 100%;
        }
    }
}
