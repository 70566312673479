// -------------------------------------
//   LISTA DE FONTES
// -------------------------------------

@mixin font-type($font-face) {
    @if $font-face == "font400" {
        font-family: 'Work Sans', sans-serif;
        font-weight: 400;
        font-style:normal;
    }

    @else if $font-face == "font500" {
        font-family: 'Work Sans', sans-serif;
        font-weight: 500;
        font-style:normal;
    }

    @else if $font-face == "font700" {
        font-family: 'Work Sans', sans-serif;
        font-weight: 700;
        font-style:normal;
    }

    @else if $font-face == "alt400" {
        font-family: 'Raleway', sans-serif;
        font-weight: 400;
        font-style:normal;
    }

    @else if $font-face == "alt400i" {
        font-family: 'Raleway', sans-serif;
        font-weight: 400;
        font-style:italic;
    }

    @else if $font-face == "alt700" {
        font-family: 'Raleway', sans-serif;
        font-weight: 700;
        font-style:normal;
    }
}
