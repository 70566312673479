// -------------------------------------
// #PAGE WRAP
// -------------------------------------

.page-six-wrap, .page-eight-wrap, .page-ten-wrap {
    @include clearfix;
}

.page-six-wrap {
    @include breakpoint(medium) {
        @include span(10 of 12);
        @include push(1 of 12);
    }
    @include breakpoint(large) {
        @include span(8 of 12);
        @include push(2 of 12);
    }
    @include breakpoint(xlarge) {
        @include span(12 of 24);
        @include push(6 of 24);
    }
}

.page-eight-wrap {
    @include breakpoint(large) {
        @include span(10 of 12);
        @include push(1 of 12);
    }
    @include breakpoint(xlarge) {
        @include span(8 of 12);
        @include push(2 of 12);
    }
}

.page-ten-wrap {
    @include breakpoint(xlarge) {
        @include span(10 of 12);
        @include push(1 of 12);
    }
}

// -------------------------------------
// #DEFAULTS
// -------------------------------------

body {
    @include font-type(font400);
    overflow-x: hidden;
    padding-top: 90px;
    @include breakpoint(xlarge) {
        padding-top: 94px;
    }
    @include breakpoint(wrapper) {
        padding-top: 103px;
    }
}

main {
    display: block;
}

[data-aos] {
  pointer-events: none;
  &.aos-animate {
    pointer-events: auto;
  }
}

[data-aos^=zoom][data-aos^=zoom].aos-init {
    transform: scale(0) !important;
}

[data-aos^=zoom][data-aos^=zoom].aos-animate {
    transform: scale(1) !important;
}

// slick item classes
#slick-banner, #slick-tratamento, #slick-location, #slick-depoimento {
    > div {
        &:not(:first-of-type) {
            display: none;
        }
    }
}
.slick-initialized {
    > div {
        &:not(:first-of-type) {
            display: block;
        }
    }
}

// hide load img
.load-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    visibility: hidden;
}

.grecaptcha-badge {
    display: none;
}

// -------------------------------------
// #MARGIN / PADDING
// -------------------------------------

.padding-sm {
    padding: $spacing-sm 0;
}

.padding-lg {
    padding: $spacing-sm 0;
    @include breakpoint(small) {
        padding: $spacing-md 0;
    }
    @include breakpoint(large) {
        padding: $spacing-lg 0;
    }
}

.margin-sm {
    margin: $spacing-sm 0;
}

.margin-lg {
    margin: $spacing-sm 0;
    @include breakpoint(small) {
        margin: $spacing-md 0;
    }
    @include breakpoint(large) {
        margin: $spacing-lg 0;
    }
}

// -------------------------------------
// #TITLES
// -------------------------------------

.r3-title {
    @include font-type(font400);
    display: block;
    &.title-medium {
        font-weight: 500;
    }
    &.title-bold {
        font-weight: 700;
    }
    a {
        position: relative;
        display: inline-block;
        transition: $t-color;
        &:active {
            top: 2px;
        }
    }
}

.title-darkblue {
    color: $darkblue;
    a {
        color: $darkblue;
        &:hover {
            color: $cyan;
        }
    }
}

.title-white {
    color: #fff;
    a {
        color: #fff;
        &:hover {
            color: $cyan;
        }
    }
}

// -------------------------------------
// #BUTTONS
// -------------------------------------

.r3-button {
    @include font-type(font400);
    position: relative;
    display: inline-block;
    transition: $t-color, $t-bg-color;
    font-size: 18px;
    border-radius: 50px;
    padding: 15px 20px;
    box-shadow: 0 0 15px rgba(#000,0.15);
    @include breakpoint(small) {
        padding: 15px 30px;
    }
    &:active {
        top: 2px;
    }
    img {
        display: inline-block;
        vertical-align: middle;
        width: 24px;
        height: 24px;
        margin-right: 10px;
    }
}

.button-darkblue {
    color: #fff;
    background-color: $darkblue;
    &:focus {
        color: #fff;
    }
    &:hover {
        color: #fff;
        background-color: $cyan;
    }
}

.button-white  {
    color: $darkblue;
    background-color: #fff;
    &:focus {
        color: $darkblue;
    }
    &:hover {
        color: #fff;
        background-color: $cyan;
    }
}

.button-cyan  {
    color: #fff;
    background-color: $cyan;
    &:focus {
        color: #fff;
    }
    &:hover {
        color: #fff;
        background-color: darken($cyan,10%);
    }
}

.button-green  {
    color: #fff;
    background-color: #40A513;
    &:focus {
        color: #fff;
    }
    &:hover {
        color: #fff;
        background-color: darken(#40A513,10%);
    }
}

.r3-link {
    @include font-type(font500);
    position: relative;
    display: inline-block;
    font-size: 18px;
    color: $cyan;
    border-bottom: 1px solid #fff;
    transition: $t-color, $t-border-color;
    &:active {
        top: 2px;
    }
    &:focus {
        color: $cyan;
    }
    &:hover {
        color: $cyan;
        border-color: $cyan;
    }
}

// -------------------------------------
// #SIZES
// -------------------------------------

.size-20, .size-24, .size-30, .size-35, .size-50 {
    line-height: $line-height-sm;
}

.size-20 {
    font-size: 20px;
}

.size-24 {
    font-size: 20px;
    @include breakpoint(large) {
        font-size: 24px;
    }
}

.size-30 {
    font-size: 24px;
    @include breakpoint(small) {
        font-size: 26px;
    }
    @include breakpoint(large) {
        font-size: 30px;
    }
}

.size-35 {
    font-size: 26px;
    @include breakpoint(small) {
        font-size: 30px;
    }
    @include breakpoint(large) {
        font-size: 35px;
    }
}

.size-50 {
    font-size: 26px;
    @include breakpoint(small) {
        font-size: 35px;
    }
    @include breakpoint(medium) {
        font-size: 40px;
    }
    @include breakpoint(large) {
        font-size: 50px;
    }
}

// -------------------------------------
// #TEXT
// -------------------------------------

.r3-text {
    h1, h2, h3, h4, h5, h6 {
        @include font-type(font400);
        color: $black;
        line-height: $line-height-sm;
        a {
            color: $black;
        }
    }
    h1 {
        font-size: 28px;
        @include breakpoint(small) {
            font-size: 32px;
        }
    }
    h2 {
        font-size: 26px;
        @include breakpoint(small) {
            font-size: 30px;
        }
    }
    h3 {
        font-size: 24px;
        @include breakpoint(small) {
            font-size: 28px;
        }
    }
    h4 {
        font-size: 22px;
        @include breakpoint(small) {
            font-size: 26px;
        }
    }
    h5 {
        font-size: 20px;
        @include breakpoint(small) {
            font-size: 24px;
        }
    }
    h6 {
        font-size: 18px;
        @include breakpoint(small) {
            font-size: 22px;
        }
    }
    p, li {
        @include font-type(font400);
        margin: 0;
        color: $black;
        line-height: $line-height-md;
        font-size: 18px;
        @include word-wrap;
        a {
            color: $black;
            transition: $t-color;
            &:hover {
                color: $cyan;
            }
        }
    }
    p, ul, ol, h1, h2, h3, h4, h5, h6 {
        margin-bottom: 20px;
    }
    &>:last-child {
        margin-bottom: 0;
    }
    &.text-white {
        p, li {
            color: #fff;
            a {
                color: #fff;
                &:hover {
                    color: $cyan;
                }
            }
        }
    }
    &.text-darkblue {
        p, li {
            color: $darkblue;
            a {
                color: $darkblue;
                &:hover {
                    color: $cyan;
                }
            }
        }
    }
    &.text-gray {
        p, li {
            color: $gray;
            a {
                color: $gray;
                &:hover {
                    color: $cyan;
                }
            }
        }
    }
    &.text-font-alt {
        p, li {
            @include font-type(alt400);
        }
    }
    &.text-font-italic {
        p, li {
            @include font-type(alt400i);
        }
    }
    &.align-center {
        text-align: center;
    }
}
